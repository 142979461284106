<template>
  <div v-if="isShowSuccess" class="modal" @click="onConfirm">
    <div class="modal-inner flex">
      <div class="box-left flex1">
        <div class="icon-weituo-success"></div>
        <div class="tit">提交委托成功！</div>
        <div class="sub-tit">周一至周日9:00-20:00,{{companyConfig.tel || '028 6463 6764'}}客服会及时给您回电，请耐心等待</div>
        <div class="btn-confirm">确定</div>
      </div>
      <div class="box-right">
        <a href="javascript:;" class="close-btn"><i class="el-icon-close colb3"></i></a>
        <img width="124" height="124" :src="downloadUrl" alt=""/>
        <div class="txt">扫一扫·下载{{companyConfig.firstName + companyConfig.lastName}}APP</div>
      </div>
    </div>
  </div>
</template>
<script>
import {mapState} from "vuex"

export default {
  props: {
    isShowSuccess: {
      type: Boolean,
      require: true
    }
  },
  methods: {
    onConfirm() {
      this.$emit("close-sucess-modal");
    }
  },
  computed: {
    ...mapState(["companyConfig"]),
    downloadUrl() {
      return this.companyConfig.downloadUrl
    }
  },
};
</script>

<style lang="less" scoped>
.modal-inner {
  width: 700px;
  height: 340px;
  .box-left {
    position: relative;
    float: left;
    width: 510px;
    height: 100%;
    padding: 70px 35px 30px;
    box-sizing: border-box;
    .icon-weituo-success {
      background-image: url("../../../assets/imgs/sprite.png");
      background-position: 0 -292px;
      width: 104px;
      height: 81px;
      position: absolute;
      left: 88px;
      top: 70px;
    }
    .tit {
      margin-bottom: 10px;
      padding-left: 170px;
      font-size: 24px;
      line-height: 1;
      font-weight: 700;
    }
    .sub-tit {
      padding-left: 170px;
      line-height: 22px;
    }
    .btn-confirm {
      margin-left: 170px;
      margin-top: 45px;
      width: 160px;
      height: 45px;
      line-height: 43px;
      font-size: 16px;
      font-weight: 700;
      border: 1px solid #d9dcde;
      border-radius: 2px;
      cursor: pointer;
      text-align: center;
    }
  }
  .box-right {
    height: 100%;
    background-color: #f5f5f6;
    position: relative;
    padding: 100px 33px 0;
    height: 100%;
    box-sizing: border-box;
    .txt {
      width: 124px;
      margin-top: 20px;
      line-height: 1;
      font-size: 12px;
      color: #9399a5;
    }
    .close-btn {
      position: absolute;
      right: 10px;
      top: 10px;
    }
  }
}
</style>


