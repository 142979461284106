import { render, staticRenderFns } from "./city-select.vue?vue&type=template&id=50db7c20&scoped=true&"
import script from "./city-select.vue?vue&type=script&lang=js&"
export * from "./city-select.vue?vue&type=script&lang=js&"
import style0 from "./city-select.vue?vue&type=style&index=0&id=50db7c20&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "50db7c20",
  null
  
)

export default component.exports